import React, {useState} from "react";
import styles from "./account.module.css";
import Button from "../common/Button";
import {Account} from "./types";
import Input from "../common/Input";

type CreateAccountProps = {
    onSaveAccount: (account: Account) => void
}

function CreateAccount(props: CreateAccountProps) {
    const [formData, setFormData] = useState<Account>({} as Account)

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log("CreateAccount() - handleChange")
        setFormData({...formData, [e.target.name]: e.target.value})
    }

    const saveAccount = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        console.log(`CreateAccount() - saveAccount - formData: ${JSON.stringify(formData)}`)
        props.onSaveAccount(formData)
    }

    return (
        <>
            <div>Opprett konto:</div>
            <div className={styles.screen__content}>
                <form onSubmit={saveAccount} className={styles.login}>
                    <Input onChange={handleChange} name="url" type="text" placeholder="Url" iconClassName="fas fa-user"/>
                    <Input onChange={handleChange} name="port" type="text" placeholder="Port" iconClassName="fas fa-lock"/>

                    <Input onChange={handleChange} name="username" type="text" placeholder="Brukernavn" iconClassName="fas fa-lock"/>
                    <Input onChange={handleChange} name="password" type="password" placeholder="Passord" iconClassName="fas fa-chevron-right"/>
                    <Button type="submit" text="Opprett konto" iconClassName="fas fa-chevron-right"/>
                </form>
            </div>
        </>
    )
}

export default CreateAccount;