
import {
    FETCH_INIT,
    FETCH_LOADING,
    FETCH_SUCCESS,
    FETCH_ERROR, FetchActionTypes, ErrorResponse,
} from "./types";

export function getFetchInit<T>(): FetchActionTypes<T> {
    return {
        type: FETCH_INIT
    }
}

export function getFetchLoading<T>(): FetchActionTypes<T> {
    console.log(`getFetchLoading:`)
    return {
        type: FETCH_LOADING
    }
}

export function getFetchSuccess<T>(response: T): FetchActionTypes<T> {
    return {
        type: FETCH_SUCCESS,
        payload: response
    };
}

export function getFetchError<T>(error: ErrorResponse): FetchActionTypes<T> {
    return {
        type: FETCH_ERROR,
        error: error
    }
}