import React, {PropsWithChildren} from 'react';

import styles from "./collapsable.module.css";
import {useCollapse} from "react-collapsed";

interface CollapsableProps extends PropsWithChildren<any> {
    minimizedText: string,
    maximizedText: string,
}

const Collapsable: React.FC<CollapsableProps> = ({minimizedText, maximizedText, children}) => {
    const {getCollapseProps, getToggleProps, isExpanded} = useCollapse();

    return (
        <>
            <div className={`${styles.collapsible}`}>
                <div className={`${styles.header}`}  {...getToggleProps()}>
                    {isExpanded ? maximizedText: minimizedText}
                </div>
                <div {...getCollapseProps()}>
                    <div className={`${styles.content}`}>
                        {children}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Collapsable;
