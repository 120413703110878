import React from 'react';
import VideoPlayer from "../VideoPlayer";
import styles from "./streaming.module.css";
import {SelectedChannelContext} from "../../context/channel/SelectedChannelContext";

function Streaming() {
    const { data} = React.useContext(SelectedChannelContext);
    console.log(`State from ChannelContext: ${JSON.stringify(data)}`);

    if (data == null) {
        return <div className={styles.centered}>Ingen kanal valgt</div>
    }
    return (

        <div className={styles.centered}>
            <div>{data.name}</div>
            <VideoPlayer url={data.location} videoFormat={data.mimeType}/>
        </div>
    );
}

export default Streaming;
