import React from 'react';

import styles from "./header.module.css";
import {Link} from "react-router-dom";

function Header() {

  return (
    <>
    <header className={styles.header}>
          <div id="menubar">
            <nav>
              <ul className={styles.menu}>
                <li>
                  <Link to={`/`}>Innstillinger</Link>
                </li>
                <li>
                  <Link to="/preview">Vis kanaler</Link>
                </li>
              </ul>
            </nav>
          </div>
    </header>
    </>
  );
}

export default Header;
