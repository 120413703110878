import {Account, AccountActionTypes, REMOVE_ACCOUNT, SET_ACCOUNT} from "./types";


export function removeAccount(): AccountActionTypes {
    return {
        type: REMOVE_ACCOUNT
    }
}
export function selectAccount(data: Account): AccountActionTypes {
    return {
        type: SET_ACCOUNT,
        payload: data
    };
}