import styles from "./loading.module.css";
import {PropsWithChildren} from "react";

interface LoadingProps extends PropsWithChildren<any> {
    loading?: boolean
    coverParentOnly?:boolean
}

const Loading = ({loading = true, coverParentOnly = true}: LoadingProps) => {
    return (
        <>
            {loading && (<div className={coverParentOnly ? `${styles.cover__parent}` : ''} >
                <div className={`${styles.image__wrapper}`}>
                    <span className={styles.loader}></span>
                </div>
            </div>)

            }
        </>
    )
}

export default Loading;