import {AccountActionTypes, AccountState, REMOVE_ACCOUNT, SET_ACCOUNT} from "./types";

export const initialAccountState = {
    data: undefined
};
export const accountReducer = () => (state: AccountState, action: AccountActionTypes): AccountState => {
    switch (action.type) {
        case SET_ACCOUNT:
            return {...state, data: action.payload};
        case REMOVE_ACCOUNT:
            return {...state, data: undefined};
        default:
            return state;
    }
};