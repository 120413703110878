import React, {PropsWithChildren} from 'react';
import Streaming from "../../components/Streaming";
import styles from "./preview.module.css";
import ChannelsList from "../../components/ChannelsList";

interface Props extends PropsWithChildren<any> {

}

function Preview(props: Props) {

    return (
        <div className={styles.boxcontainer}>
            <div className={styles.box}>
                <ChannelsList/>
            </div>
            <div className={`${styles.box}`}>
                <Streaming/>
            </div>
        </div>
    );
}

export default Preview;
