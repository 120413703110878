import {M3uMedia} from "../../lib/m3u";

export const SET_CHANNEL = "channel/SET_CHANNEL";
export const STOP_CHANNEL = "channel/STOP_CHANNEL";

export type ChannelActionTypes =
    | {type: typeof SET_CHANNEL; payload: M3uMedia }
    | {type: typeof STOP_CHANNEL };

export type ChannelState = {
    data?: M3uMedia;
}

export interface ChannelContextType {
    data?: M3uMedia;
    selectChannel: (channel: M3uMedia) => void;
    removeSelectedChannel: () => void;
}

export const initialChannelContextType: ChannelContextType = {
    data: undefined,
    selectChannel: (channel: M3uMedia) => {},
    removeSelectedChannel: () => {}
} as ChannelContextType;