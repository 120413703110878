import {IDBPDatabase, openDB} from 'idb';


export type TableData = {
    tableName: string,
    keyPath: string | null,
    autoIncrement: boolean
}

class IndexedDb {
    private dbPromise: Promise<IDBPDatabase>;

    constructor(private dbName: string, private objectStoreNames: TableData[], private version: number = 1) {
        this.dbPromise = openDB(this.dbName, this.version, {
            upgrade: (db) => {
                this.objectStoreNames.forEach((tableData) => {
                    if (!db.objectStoreNames.contains(tableData.tableName)) {
                        //db.createObjectStore(tableData.tableName, { autoIncrement: true, keyPath: 'name' }); //Will use name field from value json as key. This is the M3UMedia name field.
                        db.createObjectStore(tableData.tableName, { autoIncrement: tableData.autoIncrement, keyPath: tableData.keyPath ? tableData.keyPath : null }); //Will use name field from value json as key. This is the M3UMedia name field.
                    }
                });
            },
        });
    }

    async get(storeName: string, key: string | number) {
        const db = await this.dbPromise;
        return db.get(storeName, key);
    }

    async set(storeName: string, value: any, key?: any,) {
        const db = await this.dbPromise;
        const tx = db.transaction(storeName, 'readwrite');
        key ? tx.store.put(value, key) : tx.store.put(value)
        return tx.done;
    }

    async delete(storeName: string, key: string | number) {
        const db = await this.dbPromise;
        const tx = db.transaction(storeName, 'readwrite');
        tx.store.delete(key);
        return tx.done;
    }

    async count(storeName: string, key: string | number) {
        const db = await this.dbPromise;
        return db.count(storeName, key);
    }

    public async setBulkValue(storeName: string, values: any[]) {
        console.log('IndexedDb.setBulkValue');
        const db = await this.dbPromise;
        const tx = db.transaction(storeName, 'readwrite');
        for (const value of values) {
            await tx.store.put(value);
        }
        return tx.done
    }

    public async putBulkValue(storeName: string, values: any[]) {
        const db = await this.dbPromise;
        const tx = db.transaction(storeName, 'readwrite');
        for (const value of values) {
            await tx.store.put(value);
        }
        return tx.done
    }

    async clearTable(storeName: string) {
        const db = await this.dbPromise;
        const tx = db.transaction(storeName, 'readwrite');
        tx.store.clear()
        return tx.done;
    }

    async getAll(storeName: string) {
        const db = await this.dbPromise;
        return db.getAll(storeName);
    }

    async countRows(storeName: string) {
        const db = await this.dbPromise;
        return db.count(storeName);
    }
}

export default IndexedDb;
