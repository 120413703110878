import {ChannelActionTypes, SET_CHANNEL, STOP_CHANNEL} from "./types";
import {M3uMedia} from "../../lib/m3u";

export function removeSelectedChannel(): ChannelActionTypes {
    return {
        type: STOP_CHANNEL
    }
}
export function selectChannel(data: M3uMedia): ChannelActionTypes {
    return {
        type: SET_CHANNEL,
        payload: data
    };
}