import React from 'react';
import "../../App.css";
import CreateAccount from "./CreateAccount";
import DeleteAccount from "./DeleteAccount";
import Collapsable from "../common/Collapsable";
import {AccountContext} from "../../context/account/AccountContext";


const AccountView = () => {
    const {removeAccount, createAccount} = React.useContext(AccountContext);

    return (
        <>
            <Collapsable maximizedText={'Minimer Kontoadministrasjon'} minimizedText={'Trykk her for Kontoadministrasjon'}>
                <DeleteAccount onDeleteAccount={() => removeAccount()}/>
                <Collapsable maximizedText={'Minimer opprett konto'} minimizedText={'Trykk her for å opprette konto'}>
                    <CreateAccount onSaveAccount={(a) => createAccount(a)}/>
                </Collapsable>
            </Collapsable>
        </>
    );
}

export default AccountView;
