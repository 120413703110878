import React, {CSSProperties} from "react";
import {M3uMedia} from "../../lib/m3u";
import styles from "./channels.module.css";
import {SelectedChannelContext} from "../../context/channel/SelectedChannelContext";

type ItemProps = {
    index: number,
    style: CSSProperties | undefined;
    data: M3uMedia;
    onRowClick?: (data: M3uMedia) => void
}

function ChannelItem({data, index, style, onRowClick = () => {}}: ItemProps) {
    const keyName = data.name ? data.name : "";
    return (

            <button onClick={() => onRowClick(data)} style={style} key={index + keyName} className={styles.channel}>
                <div className={styles.number}>{index}</div>
                <div className={styles.name}>
                    {data.name}
                    <br/>
                    <small>{data.location}</small>
                </div>
            </button>

    )
}

export const Row = ({data, index, style}: any) => {
    const {selectChannel} = React.useContext(SelectedChannelContext);
    const item = data[index];
    const onRowClick = (rowData: M3uMedia) => {
        selectChannel(rowData)
    };
    return (
        <ChannelItem index={index} style={style} onRowClick={onRowClick} data={item}></ChannelItem>
    )
};