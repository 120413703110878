import React, {useEffect, useReducer} from 'react';
import {Account, AccountContextType, initialAccountContextType} from "./types";
import {removeAccount, selectAccount} from "./actions";
import {accountService} from "../../services/account/AccountService";
import {ErrorResponse} from "../../hooks/fetch/types";
import {accountReducer, initialAccountState} from "./AccountReducer";

export const AccountContext = React.createContext<AccountContextType>(initialAccountContextType);

const AccountProvider: React.FC<{ children: React.ReactNode }> = ({children}) => {
    const [account, dispatch] = useReducer(accountReducer(), initialAccountState);

    useEffect(() => {
        console.log("useEffect() Get Account")
        getAccount()
    }, []);

    const getAccount = () => {
        console.log(`AccountProvider: getAccount: ${JSON.stringify(account)}`)
        accountService.getAccount()
            .then((account) => {
                console.log("AccountProvider: getAccount OK!")
                dispatch(selectAccount(account))
            })
            .catch((error: ErrorResponse) => console.log(`AccountProvider: getAccount ERROR ${JSON.stringify(error)}`))
    };

    const deleteAccount = () => {
        accountService.remove()
            .then((account) => {
                console.log("Account Removed")
                dispatch(removeAccount());
            })
            .catch((error: ErrorResponse) => console.log(`Removing account ERROR ${JSON.stringify(error)}`))
    }

    const create = (account: Account) => {
        console.log(`AccountProvider: create: ${JSON.stringify(account)}`)

        accountService.save(account)
            .then(() => {
                console.log("Save Account OK!")
                dispatch(selectAccount(account));
            })
            .catch((error: ErrorResponse) => console.log(`Save Account ERROR ${JSON.stringify(error)}`))
    }

    return (
        <AccountContext.Provider value={{data:account.data, createAccount: create, removeAccount: deleteAccount}}>
            {children}
        </AccountContext.Provider>
    );
};

export default AccountProvider;