import React from 'react';
import Statistics from "../../components/Statistics";
import styles from "./home.module.css";
import AccountView from "../../components/Account";
import DownloadPlaylist from "../../components/DownloadPlaylist";
import Collapsable from "../../components/common/Collapsable";

function Home() {

    return (
        <div className={styles.container}>

            <div className={styles.screen__content}>
                <Statistics/>

                <AccountView/>

                <Collapsable maximizedText={'Minimer spilleliste download'} minimizedText={'Trykk her for å laste ned spilleliste'}>
                    <DownloadPlaylist/>
                </Collapsable>


            </div>
        </div>
    );
}

export default Home;
