import {IndexedDbFactory} from "../database/IndexedDbFactory";
import {ErrorResponse} from "../../hooks/fetch/types";
import {Account} from "../../components/Account/types";


class AccountService {

    private static _instance: AccountService;

    private constructor() {
        //Private constructor to prevent direct instantiation
    }

    public static get Instance()
    {
        return this._instance || (this._instance = new this());
    }

    public async getAccount(): Promise<Account> {
        console.log(`AccountService.getAccount()`);
        try {
            const data = await IndexedDbFactory.getInstance().get("keyvalue", "account");
            console.log(`Kontoer hentet fra databasen: ${JSON.stringify(data)}`);
            return data
        } catch (error) {
            console.log(`Error from getting value: ${error}`);
            throw {
                status: 500,
                statusText: JSON.stringify(error)
            } as ErrorResponse;
        }

    }

    public async save(account: Account): Promise<void> {
        console.log(`AccountService.save()`);
        try {
            await IndexedDbFactory.getInstance().set("keyvalue", account, "account");
            console.log(`Lagrer kontodetaljer i databasen: ${JSON.stringify(account)}`);
            return
        } catch (error) {
            console.log(`Error from getting value: ${error}`);
            throw {
                status: 500,
                statusText: JSON.stringify(error)
            } as ErrorResponse;
        }
    }

    public async remove(): Promise<void> {
        console.log(`AccountService.remove()`);
        try {
            await IndexedDbFactory.getInstance().delete("keyvalue", "account");
            console.log(`Kontodetaljer fjernet`);
            return
        } catch (error) {
            console.log(`Error from getting value: ${error}`);
            throw {
                status: 500,
                statusText: JSON.stringify(error)
            } as ErrorResponse;
        }
    }


}

export const accountService = AccountService.Instance;