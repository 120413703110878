import {ChannelActionTypes, ChannelState, SET_CHANNEL, STOP_CHANNEL} from "./types";

export const initialChannelState = {
    data: undefined
};
export const selectedChannelReducer = () => (state: ChannelState, action: ChannelActionTypes): ChannelState => {
    switch (action.type) {
        case SET_CHANNEL:
            return {...state, data: action.payload};
        case STOP_CHANNEL:
            return {...state, data: undefined};
        default:
            return state;
    }
};