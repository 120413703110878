import React, {useReducer, useState} from 'react';
import styles from "./download.playlist.module.css";
import "../../App.css";
import {M3uPlaylist} from "../../lib/m3u";
import {m3uResponseExtractor} from "../../hooks/fetch/responseExtractors";
import {callBackend} from "../../hooks/fetch/api";
import {getFetchError, getFetchLoading, getFetchSuccess} from "../../hooks/fetch/actions";
import {playlistService} from "../../services/playlist/PlaylistService";
import {ErrorResponse} from "../../hooks/fetch/types";
import {fetchReducer, initialFetchState} from "../../hooks/fetch/fetchReducer";
import Button from "../common/Button";
import Input from "../common/Input";
import Loading from "../common/Loading";

type DownloadPlaylistFormData = {
    url: string,
    port: string,
    username: string,
    password: string
}


function DownloadPlaylistFromInput() {
    const [formData, setFormData] = useState({} as DownloadPlaylistFormData )
    const [isSending, setIsSending] = useState(false)
    const [state, dispatch] = useReducer(fetchReducer<M3uPlaylist>(), initialFetchState);


    const downloadFromInternet = async () => {
        console.log(`DownloadPlaylist() - downloadFromInternet`);
        dispatch(getFetchLoading<M3uPlaylist>());

        return callBackend<M3uPlaylist>("GET", `${formData.url}:${formData.port}/get.php?username=${formData.username}&password=${formData.password}&type=m3u&output=ts`, {}, {}, m3uResponseExtractor)
    }

    const storePlaylistInDatabase = (playlist: M3uPlaylist) => {
        console.log("storePlaylistInDatabase")

        // don't send again while we are sending
        if (isSending) return
        // update state
        setIsSending(true)
        // send the actual request
        playlistService.save(playlist)
            .then(() => console.log("SaveAll OK!"))
            .catch((error: ErrorResponse) => console.log(`SaveAll ERROR ${JSON.stringify(error)}`))
            .finally(() => setIsSending(false)) // once the request is sent, update state again
    }


    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log("handleChange")
        setFormData({...formData, [e.target.name]: e.target.value})
    }

    const downloadPlaylist = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        console.log(`downloadPlaylist() - formData: ${JSON.stringify(formData)}`)
        downloadFromInternet()
            .then(t => {
                console.log(`useFetchPromise() - fetchData - getFetchSuccess`);
                dispatch(getFetchSuccess<M3uPlaylist>(t));
                storePlaylistInDatabase(t)
            })
            .catch(error => {
                console.log("useFetchPromise() - fetchData - error" + error);
                dispatch(getFetchError<M3uPlaylist>(error as ErrorResponse));
            });
    }

    const showContent = () => {
        if (state.isLoading) {
            return <Loading coverParentOnly={true}/>
        }
        if (state.error) {
            console.log(`Lasting feilet: error: ${JSON.stringify(state.error)}`)
        }

        return <>
            <div>Last ned spilleliste:</div>
            <div className={styles.screen__content}>
                <form onSubmit={downloadPlaylist} className={styles.login}>
                    <Input onChange={handleChange} name="url" type="text" placeholder="Url"
                           iconClassName="fas fa-user"/>
                    <Input onChange={handleChange} name="port" type="text" placeholder="Port"
                           iconClassName="fas fa-lock"/>

                    <Input onChange={handleChange} name="username" type="text" placeholder="Brukernavn"
                           iconClassName="fas fa-lock"/>
                    <Input onChange={handleChange} name="password" type="password" placeholder="Passord"
                           iconClassName="fas fa-chevron-right"/>

                    <Button type="submit" text="Last ned spilleliste" iconClassName="fas fa-chevron-right"/>
                </form>
            </div>
        </>
    }

    return (
        <>
            {showContent()}
        </>
    );
}

export default DownloadPlaylistFromInput;
