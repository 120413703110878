
export interface RequestPayload {
    [key: string]: string;
}

export interface ErrorResponse {
    status: number;
    statusText: string;
}

export const FETCH_INIT = "fetch/FETCH_INIT";
export const FETCH_LOADING = "fetch/FETCH_LOADING";
export const FETCH_SUCCESS = "fetch/FETCH_SUCCESS";
export const FETCH_ERROR = "fetch/FETCH_ERROR";

export type FetchState<T> = {
    data?: T;
    isLoading: boolean;
    error?: ErrorResponse;
}

export type FetchActionTypes<T> =
| {type: typeof FETCH_INIT }
| {type: typeof FETCH_LOADING }
| {type: typeof FETCH_SUCCESS; payload: T }
| {type: typeof FETCH_ERROR; error: ErrorResponse };