import queryString from "query-string";
import {blobExtractor, contentTypeResponseExtractor} from "./responseExtractors";
import {ErrorResponse, RequestPayload} from "./types";


type InternalPayload = RequestPayload | FormData | undefined

export interface RequestHeader {
    accept?: string,
    contentType?: string,
}

/**
 * Fetches a binary file
 */
export async function callBackendBlob(method: string, url: string, requestHeader?: RequestHeader, requestPayload?: RequestPayload): Promise<Blob> {
    console.log(`callBackendBlob - requestHeader = ${requestHeader}`);
    return callBackendInternal<Blob>(method, url, requestHeader, requestPayload, blobExtractor);
}

/**
 * Forms with fileupload etc.
 */
export async function callBackendMultipart<T>(method: string, url: string, formdata: FormData): Promise<T> {
    //For multipart forms contentType has to be set by the browser, so don't add it to this list
    const requestHeader: RequestHeader = {}

    return callBackendInternal<T>(method, url, requestHeader, formdata, contentTypeResponseExtractor);
}

/**
 * Fetches JSON (XML response is converted to JSON)
 */
export async function callBackend<T>(method: string, url: string, requestHeader: RequestHeader = {}, requestPayload: RequestPayload = {}, responseExtractor: (resp: Response) => Promise<T>): Promise<T> {
    // const requestHeader: RequestHeader = { accept: "application/json", contentType: "application/json"}
    return callBackendInternal<T>(method, url, requestHeader, requestPayload, responseExtractor);
}

async function callBackendInternal<T>(method: string, url: string, requestHeader: RequestHeader = {}, payload: InternalPayload, responseExtractor: (resp: Response) => Promise<T>) {
    console.log(`callBackendInternal - url = ${url}`);
    console.log(`callBackendInternal - requestHeader = ${JSON.stringify(requestHeader)}`);
    console.log(`callBackendInternal - payload = ${JSON.stringify(payload)}`);
    const headers = createHeaders(requestHeader);
    const fullUrl = createFullUrl(url, method, payload);

    const requestInit = createRequestInit(method, headers, payload);
    const request = new Request(fullUrl, requestInit);
    const response: Response = await fetch(request);
    if (response?.ok) {
        console.log(`callBackendInternal - response?.ok`);
        return responseExtractor(response);
    }
    //Below is handling of errorresponse from api call.
    const errorBody: any = await response.json()
    return Promise.reject(createError(response, errorBody));
}

function createHeaders(requestHeader: RequestHeader): Headers {
    const headers = new Headers();
    if (requestHeader.accept) {
        headers.set("Accept", requestHeader.accept)
    }
    if (requestHeader.contentType) {
        headers.set("Content-Type", requestHeader.contentType)
    }
    return headers;
}

function addQueryParams(url: string, queryParams?: string): string {
    if (queryParams) {
        return `${url}?${queryParams}`;
    }
    return url;
}

function createFullUrl(url: string, method: string, payload?: InternalPayload): string {
    if (method.toLowerCase() !== "get") {
        return url;
    }

    if (isFormData(payload) || (!payload)) {
        return url
    }

    return addQueryParams(url, queryString.stringify(payload));

}

function isFormData(payload: InternalPayload): payload is FormData {
    return !!payload && (payload as FormData).append !== undefined
}

function isRequestPayload(payload: InternalPayload): payload is RequestPayload {
    //Since RequestPayload can have any key names, we check that it's not formData
    return !!payload && (payload as RequestPayload).append === undefined
}

function createBody(payload: InternalPayload): string | FormData | undefined {
    if (isFormData(payload)) {
        return payload;
    }

    if (isRequestPayload(payload)) {
        return JSON.stringify(payload);
    }
}

function createRequestInit(method: string, headers: Headers, payload: InternalPayload): RequestInit {
    const requestInit: RequestInit = {
        method: method,
        headers: headers
    }

    if (method.toLowerCase() !== "get") {
        requestInit.body = createBody(payload);
    }

    return requestInit;
}

function createError(response: Response, errorBody: any) {
    console.log("errorBody: " + errorBody);
    const errorResponse: ErrorResponse = {
        status: response.status,
        statusText: response.statusText
    }
    return errorResponse;
}
