import React from 'react';

import styles from "./button.module.css";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    text: string;
    iconClassName?: string
}

const Button: React.FC<ButtonProps> = ({text, iconClassName, ...props}) => {

    return (
        <div className={`${styles.button__wrapper}`}>
            <button {...props} className={`${styles.button}`}>
                <span className={styles.button__text}>{text}</span>
                <i className={`${styles.button__icon} ${iconClassName}`}></i>
            </button>
        </div>
    );
}

export default Button;
