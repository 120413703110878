import IndexedDb, {TableData} from "./IndexedDb";

const DATABASE_NAME = "browser_player"
const TABLE_DATA = [{tableName: "playlist", keyPath: "id", autoIncrement: true} as TableData,{tableName: "keyvalue", keyPath: null, autoIncrement: false} as TableData ]

export class IndexedDbFactory {

    private static instance: IndexedDb;

    private constructor() {
        // Private constructor to prevent direct instantiation
    }

    public static getInstance(): IndexedDb {
        if (!IndexedDbFactory.instance) {
            console.log("IndexedDbFactory.getInstance - !IndexedDbFactory.instance")
            IndexedDbFactory.openDatabase()
                .then(db => {
                    console.log("Database created")
                        return db
                })
                .catch(error =>
                    console.log(`Error creating Database: ${error}`)
                );
        }
        console.log("IndexedDbFactory.getInstance - Returning instance")
        return IndexedDbFactory.instance;
    }

    private static openDatabase = async () =>{
        console.log("IndexedDbFactory.openDatabase")
        IndexedDbFactory.instance = new IndexedDb(DATABASE_NAME, TABLE_DATA);

        return IndexedDbFactory.instance
    }
}