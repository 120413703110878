import React from 'react';

import styles from "./input.module.css";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    innerRef?: React.RefObject<HTMLInputElement>
    iconClassName?: string
}

const Input: React.FC<InputProps> = ({innerRef, iconClassName, ...props}) => {

    return (
        <div className={`${styles.input__wrapper}`}>
            <i className={`${iconClassName}`}></i>
            <input ref={innerRef} {...props} />
        </div>
    );
}

export default Input;
