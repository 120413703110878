import React, {useState} from 'react';
import mpegts from "mpegts.js";
import Mpegts from "mpegts.js";
import styles from "./videoplayer.module.css";
import {MimeType} from "../../lib/m3u/m3u-model";
import Button from "../common/Button";
import {SelectedChannelContext} from "../../context/channel/SelectedChannelContext";

interface Props {
    url?: string;
    videoFormat: MimeType;
}

const videoPlayerConfig: Mpegts.Config = {
    enableWorker: true,
    liveBufferLatencyChasing: false, //False give better flow in the image. No loading indicator evry 5 seconds.
    liveBufferLatencyMaxLatency: 1.5,
    liveBufferLatencyMinRemain: 0.5,
    autoCleanupSourceBuffer: true,
    fixAudioTimestampGap: true,
}

export const VideoPlayer = ({url, videoFormat}: Props) => {
    const { removeSelectedChannel} = React.useContext(SelectedChannelContext);
    const videoRef = React.createRef<HTMLVideoElement>();
    const [isPaused, setIsPaused] = useState(videoRef.current?.paused);
    const [isAlignRight, setIsAlignRight] = useState(false);
    let player: Mpegts.Player;

    console.log(`******** Videoplayer - videoFormat: ${videoFormat}`)
    React.useEffect(() => {
        const mediaDataSource: Mpegts.MediaDataSource = {
            type: videoFormat, //'mp4', //'m2ts',  // could also be mpegts, m2ts, flv, mp4
            isLive: true,
            url: url
        }
        player = mpegts.createPlayer(mediaDataSource, videoPlayerConfig);

        // console.log(`mpegts.getFeatureList(): ${JSON.stringify(mpegts.getFeatureList())}`)
        player.attachMediaElement(videoRef.current!!);
        player.load();
        var playPromise = player.play();
        if (playPromise !== undefined) {
            playPromise.then(_ => {
                console.log("Automatic playback started! Show playing UI.")
            })
                .catch(error => {
                    console.log(`Auto-play was prevented! Show paused UI. Error: ${error}`)
                    // Auto-play was prevented. Show paused UI.
                });
        }

        player.on(Mpegts.Events.LOADING_COMPLETE, function (message: any) {
            console.log(`********** Player.on Mpegts.Events.LOADING_COMPLETE. message: ${message}`)
        });
        player.on(Mpegts.Events.RECOVERED_EARLY_EOF, function (message: any) {
            console.log(`********** Player.on Mpegts.Events.RECOVERED_EARLY_EOF. message: ${message}`)
        });
        player.on(Mpegts.Events.MEDIA_INFO, function (mediaInfo: any) {
            console.log(`********** Player.on Mpegts.Events.MEDIA_INFO. message: ${JSON.stringify(mediaInfo)}`)
            //TODO: Vis data om videoen.
            //mediaInfo format: {"mimeType":"video/mp2t; codecs=\"avc1.640020,mp4a.40.2\"","duration":null,"hasAudio":true,"hasVideo":true,"audioCodec":"mp4a.40.2","videoCodec":"avc1.640020","audioDataRate":null,"videoDataRate":null,"audioSampleRate":48000,"audioChannelCount":2,"width":1280,"height":720,"fps":50,"profile":"High","level":"3.2","refFrames":4,"chromaFormat":"4:2:0","sarNum":1,"sarDen":1,"metadata":null,"segmentCount":1,"hasKeyframesIndex":null}
        });
        player.on(Mpegts.Events.METADATA_ARRIVED, function (metadata: any) {
            console.log(`********** Player.on Mpegts.Events.METADATA_ARRIVED. message: ${JSON.stringify(metadata)}`)
        });
        player.on(Mpegts.Events.SCRIPTDATA_ARRIVED, function (data: any) {
            // console.log(`********** Player.on Mpegts.Events.SCRIPTDATA_ARRIVED. message: ${JSON.stringify(data)}`)
        });
        player.on(Mpegts.Events.TIMED_ID3_METADATA_ARRIVED, function (timed_id3_metadata: any) {
            // console.log(`********** Player.on Mpegts.Events.TIMED_ID3_METADATA_ARRIVED. message: ${JSON.stringify(timed_id3_metadata)}`)
        });
        player.on(Mpegts.Events.SMPTE2038_METADATA_ARRIVED, function (smpte2038_metadata: any) {
            // console.log(`********** Player.on Mpegts.Events.SMPTE2038_METADATA_ARRIVED. message: ${JSON.stringify(smpte2038_metadata)}`)
        });
        player.on(Mpegts.Events.PES_PRIVATE_DATA_DESCRIPTOR, function (descriptor: any) {
            // console.log(`********** Player.on Mpegts.Events.PES_PRIVATE_DATA_DESCRIPTOR. message: ${JSON.stringify(descriptor)}`)
        });
        player.on(Mpegts.Events.PES_PRIVATE_DATA_ARRIVED, function (private_data: any) {
            // console.log(`********** Player.on Mpegts.Events.PES_PRIVATE_DATA_ARRIVED. message: ${JSON.stringify(private_data)}`)
        });
        player.on(Mpegts.Events.STATISTICS_INFO, function (_statisticsInfo: any) {
            // console.log(`********** Player.on Mpegts.Events.STATISTICS_INFO. message: ${JSON.stringify(_statisticsInfo)}`)
        });
        player.on(Mpegts.Events.ERROR, function (errorType: Mpegts.ErrorTypes, details: string, info: string) {
            console.log(`********** Player.on Mpegts.Events.ERROR. ErrorType: ${errorType}`)
            console.log(`********** Player.on Mpegts.Events.ERROR. details: ${JSON.stringify(details)}`)
            console.log(`********** Player.on Mpegts.Events.ERROR. info: ${JSON.stringify(info)}`)
        });
        return function cleanup() {
            console.log(`********** VideoPlayer.useEffect - cleanup`);
            player.destroy()
        };
    }, [url]);

    const togglePause = () => {
        setIsPaused(!isPaused)
        if (!isPaused) {
            videoRef.current?.pause()
        } else {
            videoRef.current?.play()
        }
    }

    const toggleAlign = () => {
        console.log(`toggleAlign: toggleAlign: ${isAlignRight}`)
        setIsAlignRight(!isAlignRight)
    }

    return (
        <>
            <div className="align_horizontally">
            <Button onClick={removeSelectedChannel} type="button" text="Stopp video" iconClassName="fas fa-chevron-right"/>
            {isPaused  ?  (<Button onClick={togglePause} type="button" text="Play" iconClassName="fas fa-chevron-right"/>) : (<Button onClick={togglePause} type="button" text="Pause" iconClassName="fas fa-chevron-right"/>)}
            {isAlignRight  ?  (<Button onClick={toggleAlign} type="button" text="Align left" iconClassName="fas fa-chevron-right"/>) : (<Button onClick={toggleAlign} type="button" text="Align right" iconClassName="fas fa-chevron-right"/>)}

            </div>
            <div className={isAlignRight ? `${styles.preview} ${styles.alignRight}` : `${styles.preview}`}>
                <video controls ref={videoRef} id="videoElement"></video>
            </div>
        </>
    );
}

export default VideoPlayer;