import React from "react";
import Button from "../common/Button";
import {AccountContext} from "../../context/account/AccountContext";

type DeleteAccountProps = {
    onDeleteAccount: () => void
}
const DeleteAccount = (props: DeleteAccountProps) => {
    const { data} = React.useContext(AccountContext);

    const haveData = () => {
        console.log(`haveData: ${JSON.stringify(data)}`)
        if (data) {
            return true
        }
        return false
    }

    const showContent = () => {
        if (haveData()) {
            return (<div>
                <div>{data?.url}:{data?.port}</div>
                <div>Brukernavn: {data?.username}</div>
                <Button text={"Slett konto"} onClick={() => props.onDeleteAccount()}/>
            </div>)
        } else {
            return null
        }
    }


    return (
        <>
            {showContent()}
        </>
    )
}

export default DeleteAccount;