import React, {useReducer} from 'react';
import {M3uMedia} from "../../lib/m3u";
import {ChannelContextType, initialChannelContextType} from "./types";
import {selectedChannelReducer, initialChannelState} from "./SelectedChannelReducer";
import {selectChannel, removeSelectedChannel} from "./actions";

export const SelectedChannelContext = React.createContext<ChannelContextType>(initialChannelContextType);

const SelectedChannelProvider: React.FC<{ children: React.ReactNode }> = ({children}) => {
    const [channel, dispatch] = useReducer(selectedChannelReducer(), initialChannelState);
    const select = (m3uMedia: M3uMedia) => {
        dispatch(selectChannel(m3uMedia));
    };

    const remove = () => {
        dispatch(removeSelectedChannel());
    };

    return (
        <SelectedChannelContext.Provider value={{data:channel.data, selectChannel: select, removeSelectedChannel: remove}}>
            {children}
        </SelectedChannelContext.Provider>
    );
};

export default SelectedChannelProvider;