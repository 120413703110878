export const SET_ACCOUNT = "account/SET_ACCOUNT";
export const REMOVE_ACCOUNT = "account/REMOVE_ACCOUNT";

export type AccountActionTypes =
    | {type: typeof SET_ACCOUNT; payload: Account }
    | {type: typeof REMOVE_ACCOUNT };

export type Account = {
    url: string,
    port: string,
    username: string,
    password: string
}

export type AccountState = {
    data?: Account;
}

export interface AccountContextType {
    data?: Account;
    createAccount: (account: Account) => void;
    removeAccount: () => void;
}

export const initialAccountContextType: AccountContextType = {
    data: undefined,
    createAccount: (account: Account) => {},
    removeAccount: () => {}
} as AccountContextType;
