
import {
    FETCH_INIT,
    FETCH_LOADING,
    FETCH_SUCCESS,
    FETCH_ERROR,
    FetchState, FetchActionTypes
} from "./types";

export const initialFetchState = {
    isLoading: false,
    error: undefined,
    data: undefined
};

export const fetchReducer = <T>() => (state: FetchState<T>, action: FetchActionTypes<T>): FetchState<T> => {
    switch (action.type) {
        case FETCH_INIT:
            return {
                ...state,
                 isLoading: false,
                error: undefined,
                data: undefined
            };
        case FETCH_LOADING:
            return {...state, isLoading: true, error: undefined};
        case FETCH_SUCCESS:
            return {...state, isLoading: false, data: action.payload};
        case FETCH_ERROR:
            return {...state, isLoading: false, error: action.error};
    }
};